import CloseIcon from "../svgs/CloseIcon";
import { useNavigate } from "react-router-dom";
import backend_services from "src/services/backend_service";
import Spinner from "../elements/Spinner";
import { useAuth } from "src/context/AuthContext";
import { useState } from "react";
import { InitialListItem } from "src/models/InitialList";
import { toast } from "react-toastify";

const PeopleFinder = ({
  item,
  handleClose,
}: {
  item: InitialListItem;
  handleClose: () => void;
}) => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [website, setWebsite] = useState("");
  const [jobTitles, setJobTitles] = useState("");
  const navigator = useNavigate();

  async function handleMessageSend() {
    try {
      setLoading(true);
      if (user) {
        const result = await backend_services
          .fetchProtectedData(
            `/initial_lists/peopleFinder`,
            user.getIdToken(),
            undefined,
            "POST",
            {
              website: website,
              jobTitles: jobTitles,
            },
          )
          .then((res) => {
            setLoading(false);
            return res.json();
          });
        if (
          result.data &&
          result.data.chat_id &&
          typeof result.data.chat_id === "string"
        ) {
          navigator(`/chat/${result.data.chat_id}`);
        } else {
          if (result.message) {
            toast.error(result.message);
          } else if (result.detail) {
            toast.error(result.detail);
          }
        }
      }
    } catch (error) {
      console.error("Error while fetching data:", error);
      toast.error("An error occurred while searching. Please try again later.");
      setLoading(false); // Ensure loader stops in case of error
    }
  }

  return (
    <div
      className={`fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform ${
        loading ? "opacity-95" : null
      } z-50 w-full max-w-[700px] rounded-lg bg-white px-8 py-4 shadow-lg`}
    >
      {loading && <Spinner text="Searching" onWhiteBackground />}
      <div className="flex flex-col gap-5">
        <div className="grid grid-cols-6 items-center">
          <img
            src="https://cdn.prod.website-files.com/6697d10a891d3b0b8f8a6628/66e5293867d5eeb317ff15b5_People%20Finder.png"
            alt="People Finder"
            className="h-12 w-12"
          />
          <div className="text-nowrap col-span-4 col-start-2 justify-self-center text-center text-xl font-bold text-gray-900">
            <h2>{item.description}</h2>
          </div>
          <button
            className="col-end-7 justify-self-end text-gray-500 hover:text-gray-700"
            onClick={handleClose}
          >
            <CloseIcon />
          </button>
        </div>
        <hr className="w-full" />
        <div className="relative grid gap-10">
          <div className="flex flex-col gap-2">
            <div className="flex gap-2">
              <p>Website</p>
            </div>
            {/* Enlarged Website Input Field */}
            <textarea
              placeholder={
                "Enter the website\nExample:\nhttps://www.company1.com"
              }
              onChange={(e) => setWebsite(e.target.value)}
              className="peer inline-flex w-full resize-none items-center justify-center gap-2.5 rounded-md border-2 border-neutral-400 bg-white py-3.5 pl-3 pr-11 shadow-[2px_2px_20px_0px_#00000014] outline-none hover:border-purple"
              style={{ minHeight: "150px" }} // Responsive height
            ></textarea>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex gap-2">
              <p>List of Job Titles Needed</p>
            </div>
            {/* Single-line Job Titles Input */}
            <input
              type="text"
              placeholder={
                "Enter the list of job titles, separated by commas\nExample: Chief Technology Officer, Head of Marketing"
              }
              onChange={(e) => setJobTitles(e.target.value)}
              className="peer inline-flex w-full items-center justify-center gap-2.5 rounded-md border-2 border-neutral-400 bg-white py-3.5 pl-3 pr-11 shadow-[2px_2px_20px_0px_#00000014] outline-none hover:border-purple"
            />
          </div>
          <hr className="w-full" />
          <div className="flex justify-between">
            <div className="items-center">
              Reminder: 1 credit for 1 people search
            </div>
            <button
              onClick={handleMessageSend}
              className="flex items-center gap-2 rounded bg-purple p-[7px] text-white outline-none hover:bg-purpleHover focus:bg-purpleHover"
            >
              <p>Search</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PeopleFinder;
