import * as React from "react";
const ClearIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.3919 12.085L15.9599 3.55005L7.06836 10.25L13.7509 18.5L15.4289 17.332L22.3919 12.085Z"
      stroke="#111010"
      strokeWidth={1.5}
      strokeLinejoin="round"
    />
    <path
      d="M13.7507 18.5L11.8312 20.0375L6.5497 20.037L5.2497 18.312L2.0332 14.044L7.2507 10.1125"
      stroke="black"
      strokeWidth={1.5}
      strokeLinejoin="round"
    />
    <path
      d="M6.60352 20.0361H22.2835"
      stroke="black"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
  </svg>
);
export default ClearIcon;
