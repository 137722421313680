import CloseIcon from "../svgs/CloseIcon";
import { useNavigate } from "react-router-dom";
import SearchIcon from "../svgs/Search";
import backend_services from "src/services/backend_service";
import Spinner from "../elements/Spinner";
import { useAuth } from "src/context/AuthContext";
import { useState } from "react";
import { InitialListItem } from "src/models/InitialList";
import { toast } from "react-toastify";

const SalesNavSearchList = ({
  item,
  handleClose,
}: {
  item: InitialListItem;
  handleClose: () => void;
}) => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [searchURL, setSearchURL] = useState("");
  const [sessionCookies, setSessionCookies] = useState("");
  const navigator = useNavigate();

  async function handleMessageSend() {
    try {
      setLoading(true);
      if (user) {
        const result = await backend_services
          .fetchProtectedData(
            `/initial_lists/salesNavSearch`,
            user.getIdToken(),
            undefined,
            "POST",
            {
              searchURL,
              sessionCookies,
            },
          )
          .then((res) => {
            setLoading(false);
            return res.json();
          });
        if (
          result.data &&
          result.data.chat_id &&
          typeof result.data.chat_id === "string"
        ) {
          navigator(`/chat/${result.data.chat_id}`);
        } else {
          if (result.message) {
            toast.error(result.message);
          } else if (result.detail) {
            toast.error(result.detail);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div
      className={`fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform ${loading ? "opacity-95" : null} z-50 rounded-lg bg-white px-8 py-4 shadow-lg w-[700px]`}
    >
      {loading && <Spinner text="Searching" onWhiteBackground />}
      <div className="flex flex-col gap-5">
        <div className="grid grid-cols-6 items-center">
          <img
            src="/linkedin_icon.png"
            alt="Sales Navigator"
            className="h-12 w-12"
          />
          <div className="text-nowrap col-span-4 col-start-2 justify-self-center text-center text-xl font-bold text-gray-900">
            <h2>{item.description}</h2>
          </div>
          <button
            className="col-end-7 justify-self-end text-gray-500 hover:text-gray-700"
            onClick={handleClose}
          >
            <CloseIcon />
          </button>
        </div>
        <hr className="w-full" />
        <div className="relative grid gap-10">
          <div className="flex flex-col gap-2">
            <div className="flex gap-2">
              <p>Search URL</p>
            </div>
            <textarea
              placeholder={
                "https://www.linkedin.com/sales/search/company?query=%"
              }
              onChange={(e) => setSearchURL(e.target.value)}
              className="peer inline-flex max-h-14 w-full resize-none items-center justify-center gap-2.5 rounded-md border-2 border-neutral-400 bg-white py-3.5 pl-3 pr-11 shadow-[2px_2px_20px_0px_#00000014] outline-none hover:border-purple md:pl-4"
            ></textarea>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex gap-2">
              <p>Session Cookies</p>
            </div>
            <textarea
              placeholder={"Insert your session cookies here"}
              onChange={(e) => setSessionCookies(e.target.value)}
              className="peer inline-flex max-h-14 w-full resize-none items-center justify-center gap-2.5 rounded-md border-2 border-neutral-400 bg-white py-3.5 pl-3 pr-11 shadow-[2px_2px_20px_0px_#00000014] outline-none hover:border-purple md:pl-4"
            ></textarea>
          </div>
          <hr className="w-full" />
          <div className="flex justify-between">
            <div className="items-center">
              Reminder: Ensure you have a valid LinkedIn session
            </div>
            <button
              onClick={handleMessageSend}
              className="flex items-center gap-2 rounded bg-purple p-[7px] text-white outline-none hover:bg-purpleHover focus:bg-purpleHover"
            >
              <p>Search</p>
              <div>
                <SearchIcon height="20" width="20" />
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesNavSearchList;
